@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Old Standard TT", serif;
}
.bg-menu {
  background-color: #202020;
}
.text-primary {
  color: #aaa;
}
.bg-secondary {
  background-color: #383838;
}
.active {
  font-weight: 600;
}

.cuttextarea {
  height: 70px;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}
.h-44 {
  height: 11rem;
}
@media (min-width: 768px) {
  .md\:h-44 {
    height: 11rem;
  }
}

.fixedTopLeft {
  position: fixed;
  left: 0;
  top: 0;
  width: 50%;
  height: 50%;
}

.fixedTopRight {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
}

.fixedBottomLeft {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 50%;
}

.fixedBottomRight {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
}

.invisibleObject {
  opacity: 0;
}

.invisibleObject:hover {
  opacity: 0.2;
}

.invisibleObject,
.invisibleObject8 {
  opacity: 0;
}

.invisibleObject8 {
  opacity: 0.5;
  background-color: black;
}
.is-playing .invisibleObject8 {
  opacity: 0;
}

.is-playing .invisibleObject8:hover {
  opacity: 0.2;
  background-color: black;
}

.fixedBottomRightYoutube {
  position: fixed;
  top: 10px;
  bottom: 50%;
  right: 5px;
  width: 50%;
  overflow: hidden;
}

.fixedBottomLeftYoutube {
  position: fixed;
  top: 10px;
  bottom: 50%;
  left: 0;
  width: 50%;
  overflow: hidden;
}

.fixedBottomRightYoutubeCorner {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 55px;
  height: 35px;
}

.xltext {
  font-size: 55px;
}

.fixedBottomLeftYoutubeCorner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 55px;
  height: 35px;
}
.extension {
  min-width: 600px;
  min-height: 800px;
}
.extension .hide-in-extension {
  display: none;
}
.search-box {
  right: 1px;
  top: -36px;
}
.grayscale {
  filter: grayscale(100%);
}

@keyframes sk_bg_animation {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes sk_bg_animation {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.loading {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: sk_bg_animation;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 800px 104px;
  width: 100%;
}
body {
  overflow-y: scroll;
}

* {
  scrollbar-color: #9f9f9f white; /* thumb and track color */
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  width: 14px; /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: #a7a5a5; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid white; /* creates padding around scroll thumb */
}

body {
  overflow-y: scroll;
}
.grayscale {
  opacity: 0.5;
}
.dayExposedField {
  max-width: 435px;
}
.dayExposedField button {
  min-width: 35px;
}
.yearExposedField {
  max-width: 590px;
}
.yearExposedField button {
  min-width: 35px;
}
.surround-parenthesis::before {
  content: "(";
}
.surround-parenthesis::after {
  content: ")";
}
.surround-parenthesis:empty::before {
  content: "";
}
.surround-parenthesis:empty::after {
  content: "";
}
.max-h-20 {
  max-height: 5rem;
}
.max-h-24 {
  max-height: 6rem;
}
:focus {
  outline: none;
}
h1,
h2 {
  font-size: 1.5rem;
  line-height: 3rem;
}
h3,
h4 {
  font-size: 1.25rem;
  line-height: 2rem;
}
.train-width {
  width: calc(100vw - 10px);
}
.train-height {
  height: calc(100vh - 170px);
}

.bg-black-opacity-10 {
  background-color: #00000030;
}
.min-h-12 {
  min-height: 3rem;
}
.min-h-6 {
  min-height: 1.5rem;
}
.w-600px {
  width: 600px;
}
.w-800px {
  width: 800px;
}
.to-transparent {
  --tw-gradient-to: transparent;
}
.from-white {
  --tw-gradient-from: white;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgb(255 255 255 / 0));
}
.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}
.digi-color {
  color: rgba(94, 63, 36);
}
.tinymceview h1,
.tinymceview h3 {
  color: rgba(94, 63, 36);
}
.grow {
  flex-grow: 1;
}
.grow-2 {
  flex-grow: 100;
}
.max-h-95 {
  max-height: 90vh;
  overflow-y: auto;
}
.min-w-60 {
  min-width: 15rem;
}
