.scroll-snap-x-item {
  scroll-snap-align: center;
  min-width: calc(100% - 10px);
}
.scroll-snap-x {
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  max-height: 95vh;
}
.scroll-snap-x-outer {
  width: calc(100% - 10px);
}
@media (min-width: 768px) {
  .scroll-snap-x-outer {
    width: calc(100% - 100px);
  }
  .scroll-snap-x-item {
    scroll-snap-align: center;
    min-width: calc(100% - 100px);
  }
}
