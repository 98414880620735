.video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  margin-bottom: 20px;
}
.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
